<template>
  <div class="row" style="margin-top: 1.25rem">
    <div class="col" v-for="(task, idx) in tasks" :key="idx" @click="handleTileClick(idx)">
      <div :class="tileClass(idx)" role="alert">
        {{idx + 1}}
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: "MathGameTiles",
  props: {
    currentTaskIdx: {
      type: Number,
      required: true,
    },
    tasks: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState("mathStore", {
      solutionAchieved: (state) => state.solutionAchieved,
    }),
  },
  methods: {
    tileClass(idx) {
      if(this.solutionAchieved) {
        return "alert alert-success";
      }

      if(idx === this.currentTaskIdx) {
        return "alert alert-warning";
      }
      const task = this.tasks[idx];
      if(!task){
        return "alert alert-secondary";
      }
      if(task.completed){
        if(task.correct) {
          return "alert alert-success";
        }
        return "alert alert-danger";
      }
      return "alert alert-secondary";
    },
    handleTileClick(idx) {
      this.$store.commit("mathStore/goToTask", {
        taskIdx: idx,
      });
    }
  }
}

</script>

<style scoped>

</style>