<template>
  <div :class="answerClass" :style="answerCursor" role="alert" @click="handleAnswerClicked">
    <h3 class="m-0" v-html="renderedAnswer"/>
  </div>
</template>

<script>

import {mapState} from "vuex";
import katex from 'katex';
import 'katex/dist/katex.min.css'; // Import the KaTeX CSS for styling

export default {
  name: "MathResult",
  data() {
    return {}
  },
  props: {
    taskId: {
      type: String,
      default: ''
    },
    game: {
      type: String,
      required: true
    },
    answer: {
      type: String,
      default: ''
    },
  },
  computed: {
    ...mapState("mathStore", {
      tasks: (state) => state.tasks,
      currentTaskIdx: (state) => state.currentTaskIdx,
      isAssignment: (state) => state.isAssignment,
    }),
    taskGame() {
      return this.tasks[this.currentTaskIdx]?.game || this.game;
    },
    renderedAnswer() {
      if(this.taskGame === "flags"){
        return this.$t("countries." + this.answer);
      }
      if(this.taskGame === "cities"){
        return this.$t("cities." + this.answer);
      }

      try {
        return katex.renderToString(this.answer);
      } catch (error) {
        return this.answer;
      }
    },
    isAnswerSelectedForTask() {
      return this.tasks[this.currentTaskIdx]?.selected !== null;
    },
    answerCursor() {
      if(!this.isAssignment){
        return { cursor: "default" };
      }

      if(this.isAnswerSelectedForTask) {
        return { cursor: "not-allowed" };
      }
      return { cursor: "default" };
    },
    answerClass() {
      const baseClass = "alert";
      let colorClass = "alert-secondary";

      const selectedAnswer = this.tasks[this.currentTaskIdx].selected;
      const correctAnswer = this.tasks[this.currentTaskIdx].correct;

      if(selectedAnswer !== null && selectedAnswer.toString() === this.answer.toString()) {
        if(correctAnswer){
          colorClass = "alert-success";
        }
        else {
          colorClass = "alert-danger";
        }
      }
      return baseClass + " " + colorClass;
    }
  },
  methods: {
    handleAnswerClicked() {
      this.$store.commit("mathStore/setSelected", {
        taskId: this.taskId,
        value: this.answer,
      });
    }
  }
}

</script>