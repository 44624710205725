<template>
  <div class="row" style="margin-top: 1.25rem">
    <div class="col" style="white-space: nowrap" v-for="(answer, idx) in this.results" :key="idx">
      <MathResult
        :task-id="taskId"
        :game="game"
        :answer="answer.toString()"
      />
    </div>
  </div>
</template>

<script>

import MathResult from "@/pages/games/education/MathResult.vue";

export default {
  name: "MathResults",
  components: {MathResult},
  props: {
    taskId: {
      type: String,
      default: ''
    },
    game: {
      type: String,
      required: true
    },
    results: {
      type: Array,
      default: () => []
    }
  },
}

</script>