<template>
  <div id="game-actions" v-if="showedActions.length > 0">
    <button
        type="button"
        v-if="canCreateNewGame"
        @click="newGame()"
        class="btn btn-primary act"
    >
      {{ $t("games.dispatch.newGame") }}
    </button>
    <button
        type="button"
        v-if="canResetGame"
        @click="resetGrid()"
        class="btn btn-outline-warning act"
    >
      {{ $t("games.dispatch.restartGame") }}
    </button>
    <button
        type="button"
        v-if="canCheckState"
        @click="showCurrentState()"
        class="btn btn-danger act"
    >
      Preveri stanje
    </button>
  </div>
</template>

<script>
import {includes} from "lodash";
import router from "@/config/PageRoutes";

export default {
  name: "GameActions",
  props: {
    game: {
      type: String,
      required: true
    },
    mathGame: {
      type: String,
      required: false
    },
    showedActions: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    canCreateNewGame() {
      return includes(this.showedActions, "newGame");
    },
    canResetGame() {
      return includes(this.showedActions, "resetGame");
    },
    canCheckState() {
      return includes(this.showedActions, "checkState");
    },
  },
  methods: {
    newGame() {
      const gameName = this.mathGame ? this.mathGame : this.game;
      router.push("/games/" + gameName);
    },
    resetGrid() {
      this.$store.commit(this.game + "Store/resetGrid");
    },
    showCurrentState() {
      this.$store.commit(this.game + "Store/showCurrentState");
    },
  }
}
</script>

<style scoped>

</style>